import { Scale, Note } from "@tonaljs/tonal";

const getScaleByNoteAndType = (note, type) => {
  // check that note is valid in Note
  if (Note.get(note).empty) {
    throw new Error("Invalid note");
  }
  if (Scale.get(type).empty) {
    throw new Error("Invalid scale type");
  }

  return Scale.get(`${note} ${type}`);
};

export {
  getScaleByNoteAndType
};