// dictionary of piano notes to midi codes
const midiCodes = {
  'C0': 12,
  'C#0': 13,
  'Db0': 13,
  'D0': 14,
  'D#0': 15,
  Eb0: 15,
  E0: 16,
  F0: 17,
  'F#0': 18,
  Gb0: 18,
  G0: 19,
  'G#0': 20,
  Ab0: 20,
  A0: 21,
  'A#0': 22,
  Bb0: 22,
  B0: 23,
  C1: 24,
  'C#1': 25,
  Db1: 25,
  D1: 26,
  'D#1': 27,
  Eb1: 27,
  E1: 28,
  F1: 29,
  'F#1': 30,
  Gb1: 30,
  G1: 31,
  'G#1': 32,
  Ab1: 32,
  A1: 33,
  'A#1': 34,
  Bb1: 34,
  B1: 35,
  C2: 36,
  'C#2': 37,
  Db2: 37,
  D2: 38,
  'D#2': 39,
  Eb2: 39,
  E2: 40,
  F2: 41,
  'F#2': 42,
  Gb2: 42,
  G2: 43,
  'G#2': 44,
  Ab2: 44,
  A2: 45,
  'A#2': 46,
  Bb2: 46,
  B2: 47,
  C3: 48,
  'C#3': 49,
  Db3: 49,
  D3: 50,
  'D#3': 51,
  Eb3: 51,
  E3: 52,
  F3: 53,
  'F#3': 54,
  Gb3: 54,
  G3: 55,
  'G#3': 56,
  Ab3: 56,
  A3: 57,
  'A#3': 58,
  Bb3: 58,
  B3: 59,
  C4: 60,
  'C#4': 61,
  Db4: 61,
  D4: 62,
  'D#4': 63,
  Eb4: 63,
  E4: 64,
  F4: 65,
  'F#4': 66,
  Gb4: 66,
  G4: 67,
  'G#4': 68,
  Ab4: 68,
  A4: 69,
  'A#4': 70,
  Bb4: 70,
  B4: 71,
  C5: 72,
  'C#5': 73,
  Db5: 73,
  D5: 74,
  'D#5': 75,
  Eb5: 75,
  E5: 76,
  F5: 77,
  'F#5': 78,
  Gb5: 78,
  G5: 79,
  'G#5': 80,
  Ab5: 80,
  A5: 81,
  'A#5': 82,
  Bb5: 82,
  B5: 83,
  C6: 84,
  'C#6': 85,
  Db6: 85,
  D6: 86,
  'D#6': 87,
  Eb6: 87,
  E6: 88,
  F6: 89,
  'F#6': 90,
  Gb6: 90,
  G6: 91,
  'G#6': 92,
  Ab6: 92,
  A6: 93,
  'A#6': 94,
  Bb6: 94,
  B6: 95,
  C7: 96,
  'C#7': 97,
  Db7: 97,
  D7: 98,
  'D#7': 99,
  Eb7: 99,
  E7: 100,
  F7: 101,
  'F#7': 102,
  Gb7: 102,
  G7: 103,
  'G#7': 104,
  Ab7: 104,
  A7: 105,
  'A#7': 106,
  Bb7: 106,
  B7: 107,
  C8: 108,
  'C#8': 109,
  Db8: 109,
  D8: 110,
  'D#8': 111,
  Eb8: 111,
  E8: 112,
  F8: 113,
  'F#8': 114,
  Gb8: 114,
  G8: 115,
  'G#8': 116,
  Ab8: 116,
  A8: 117,
  'A#8': 118,
  Bb8: 118,
  B8: 119,
  C9: 120,
  'C#9': 121,
  'Db9': 121,
  'D9': 122,
  'D#9': 123,
  'Eb9': 123,
  'E9': 124,
  'F9': 125,
  'F#9': 126,
  'Gb9': 126,
  'G9': 127,
};

const listOfNotes = [
  'C0', 'C#0', 'Db0', 'D0', 'D#0', 'Eb0', 'E0', 'F0', 'F#0', 'Gb0', 'G0', 'G#0', 'Ab0', 'A0', 'A#0', 'Bb0', 'B0',
  'C1', 'C#1', 'Db1', 'D1', 'D#1', 'Eb1', 'E1', 'F1', 'F#1', 'Gb1', 'G1', 'G#1', 'Ab1', 'A1', 'A#1', 'Bb1', 'B1',
  'C2', 'C#2', 'Db2', 'D2', 'D#2', 'Eb2', 'E2', 'F2', 'F#2', 'Gb2', 'G2', 'G#2', 'Ab2', 'A2', 'A#2', 'Bb2', 'B2',
  'C3', 'C#3', 'Db3', 'D3', 'D#3', 'Eb3', 'E3', 'F3', 'F#3', 'Gb3', 'G3', 'G#3', 'Ab3', 'A3', 'A#3', 'Bb3', 'B3',
  'C4', 'C#4', 'Db4', 'D4', 'D#4', 'Eb4', 'E4', 'F4', 'F#4', 'Gb4', 'G4', 'G#4', 'Ab4', 'A4', 'A#4', 'Bb4', 'B4',
  'C5', 'C#5', 'Db5', 'D5', 'D#5', 'Eb5', 'E5', 'F5', 'F#5', 'Gb5', 'G5', 'G#5', 'Ab5', 'A5', 'A#5', 'Bb5', 'B5',
  'C6', 'C#6', 'Db6', 'D6', 'D#6', 'Eb6', 'E6', 'F6', 'F#6', 'Gb6', 'G6', 'G#6', 'Ab6', 'A6', 'A#6', 'Bb6', 'B6',
  'C7', 'C#7', 'Db7', 'D7', 'D#7', 'Eb7', 'E7', 'F7', 'F#7', 'Gb7', 'G7', 'G#7', 'Ab7', 'A7', 'A#7', 'Bb7', 'B7',
  'C8', 'C#8', 'Db8', 'D8', 'D#8', 'Eb8', 'E8', 'F8', 'F#8', 'Gb8', 'G8', 'G#8', 'Ab8', 'A8', 'A#8', 'Bb8', 'B8',
  'C9', 'C#9', 'Db9', 'D9', 'D#9', 'Eb9', 'E9', 'F9', 'F#9', 'Gb9', 'G9', 'G#9', 'Ab9', 'A9', 'A#9', 'Bb9', 'B9',
]

const getMidiCodeFromNote = (noteName) => {
  return midiCodes[noteName];
};

const getNotesFromMidiCode = (midiCode) => {
  const notes = [];
  for (const note in midiCodes) { 
    if (midiCodes[note] === midiCode) {
      notes.push(note);
    }; 
  };
  return notes;
};

const validNotes = [
  'C0',
  'C#0',
  'Db0',
  'D0',
  'D#0',
  'Eb0',
  'E0',
  'F0',
  'F#0',
  'Gb0',
  'G0',
  'G#0',
  'Ab0',
  'A0',
  'A#0',
  'Bb0',
  'B0',
  'C1',
  'C#1',
  'Db1',
  'D1',
  'D#1',
  'Eb1',
  'E1',
  'F1',
  'F#1',
  'Gb1',
  'G1',
  'G#1',
  'Ab1',
  'A1',
  'A#1',
  'Bb1',
  'B1',
  'C2',
  'C#2',
  'Db2',
  'D2',
  'D#2',
  'Eb2',
  'E2',
  'F2',
  'F#2',
  'Gb2',
  'G2',
  'G#2',
  'Ab2',
  'A2',
  'A#2',
  'Bb2',
  'B2',
  'C3',
  'C#3',
  'Db3',
  'D3',
  'D#3',
  'Eb3',
  'E3',
  'F3',
  'F#3',
  'Gb3',
  'G3',
  'G#3',
  'Ab3',
  'A3',
  'A#3',
  'Bb3',
  'B3',
  'C4',
  'C#4',
  'Db4',
  'D4',
  'D#4',
  'Eb4',
  'E4',
  'F4',
  'F#4',
  'Gb4',
  'G4',
  'G#4',
  'Ab4',
  'A4',
  'A#4',
  'Bb4',
  'B4',
  'C5',
  'C#5',
  'Db5',
  'D5',
  'D#5',
  'Eb5',
  'E5',
  'F5',
  'F#5',
  'Gb5',
  'G5',
  'G#5',
  'Ab5',
  'A5',
  'A#5',
  'Bb5',
  'B5',
  'C6',
  'C#6',
  'Db6',
  'D6',
  'D#6',
  'Eb6',
  'E6',
  'F6',
  'F#6',
  'Gb6',
  'G6',
  'G#6',
  'Ab6',
  'A6',
  'A#6',
  'Bb6',
  'B6',
  'C7',
  'C#7',
  'Db7',
  'D7',
  'D#7',
  'Eb7',
  'E7',
  'F7',
  'F#7',
  'Gb7',
  'G7',
  'G#7',
  'Ab7',
  'A7',
  'A#7',
  'Bb7',
  'B7',
  'C8',
  'C#8',
  'Db8',
  'D8',
  'D#8',
  'Eb8',
  'E8',
  'F8',
  'F#8',
  'Gb8',
  'G8',
  'G#8',
  'Ab8',
  'A8',
  'A#8',
  'Bb8',
  'B8',
  'C9',
  'C#9',
  'Db9',
  'D9',
  'D#9',
  'Eb9',
  'E9',
  'F9',
  'F#9',
  'Gb9',
  'G9',
];
// Convert note name to frequency
const noteToFreq = (noteName) => {
  // check if note is in the valid notes array
  if (!validNotes.includes(noteName)) {
    return -1; 
  }

  // get frequency
  const midiCode = midiCodes[noteName];
  const frequency = 440 * Math.pow(2, (midiCode - 69) / 12);
  return frequency;
};

const freqToNotes = (frequency) => {
  // get midi code
  const midiCode = Math.round(12 * (Math.log(frequency / 440) / Math.log(2))) + 69;
  // get note name
  const notes = getNotesFromMidiCode(midiCode);
  return notes;
}

export {
  noteToFreq,
  getMidiCodeFromNote,
  getNotesFromMidiCode,
  freqToNotes,
  midiCodes,
  listOfNotes,
};
