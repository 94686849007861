import React from 'react';
import './App.css';

import BpmCalculator from './components/BpmCalculator';
import NoteToFrequency from './components/NoteToFrequency';
import ScaleGenerator from './components/ScaleGenerator';
import PageContainer from './components/PageContainer';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home'


function App() {
  return (
    <div className='App parent'>
      <Router>
        <Switch>
          <Route path='/bpm-calculator'>
            <BpmCalculator></BpmCalculator>
          </Route>
          <Route path='/frequency-converter'>
            <NoteToFrequency></NoteToFrequency>
          </Route>
          <Route path='/scale-generator'>
            <ScaleGenerator></ScaleGenerator>
          </Route>
          <Route path='/test'>
            <PageContainer></PageContainer>
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
