import React, { useState } from 'react';
import { noteToFreq, midiCodes } from '../utils/musicUtils';
import FrequencyCalculator from './FrequencyCalculator';
import './NoteToFrequency.css';
import PageContainer from './PageContainer';

export default function NoteToFrequency() {
  return (
    <PageContainer name="Frequencies">
      <FrequencyCalculator></FrequencyCalculator>
      <br></br>
      <table className="note-table">
        <thead>
          <tr>
            <th>Note</th>
            <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(midiCodes).map((noteName, index) => (
            <tr key={index}>
              <td>{noteName}</td>
              <td>{noteToFreq(noteName)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PageContainer>
  );
}
