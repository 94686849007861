import React, { useState } from 'react';
import { bpmFromTaps } from '../utils/beatUtils';
import { pushAndGetNLatestEntries } from '../utils/arrayUtils';
import './BpmCalculator.css';
import PageContainer from './PageContainer';

export default function BpmCalculator() {
  const [clicks, setClicks] = useState([]);

  const handleClick = (e) => {
    setClicks(pushAndGetNLatestEntries(clicks, 4, new Date().getTime()));
  };

  return (
    <PageContainer name="Tap for BPM">
      <div className='div1'>
        <button className="bpm-button" onClick={handleClick}>
          {clicks.length > 2 ? bpmFromTaps(clicks) : 'tap'}
        </button>
      </div>
    </PageContainer>
  );
}
