import React from 'react';
import './PageContainer.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function PageContainer({ children, name }) {
  const [clicked, setClicked] = React.useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setClicked(!clicked);
  };

  return (
    <div>
      <div className='topnav'>
        <a href='/' id='title'>
          muse
        </a>
        <div id='links' className={clicked ? 'clicked' : 'not-clicked'}>
          <a href='/blog'>blog</a>
          <a href='/about'>about</a>
          <a href='/contact'>contact</a>
        </div>
        <a href='#' className='icon' onClick={handleClick}>
          <i className='fas fa-bars'></i>
        </a>
      </div>
      <h3 className="page-name">{name}</h3>
      {children}
    </div>
  );
}
