import React, { useState } from 'react';
import { getScaleByNoteAndType } from '../utils/scaleGenerator';
import { listOfNotes } from '../utils/musicUtils';
import { Scale, Note } from '@tonaljs/tonal';
import PageContainer from './PageContainer';
import './ScaleGenerator.css';

const sortedScales = Scale.names().sort();

const NotesDropDown = ({ init: note, onChange }) => {
  // return a drop-down list of notes populated from listOfNotes
  return (
    <select value={note} onChange={(e) => onChange(e.target.value)}>
      {listOfNotes.map((note) => (
        <option key={note} value={note}>
          {note}
        </option>
      ))}
    </select>
  );
};

// react component with useState hook that has a dropdown for selecting a scale populated from Scale.names
const ScalesDropDown = ({ init: scale, onChange }) => {
  return (
    <select value={scale} onChange={(e) => onChange(e.target.value)}>
      {sortedScales.map((scale) => (
        <option key={scale} value={scale}>
          {scale}
        </option>
      ))}
    </select>
  );
};

export default function ScaleGenerator() {
  const [note, setNote] = useState("C3");
  const [scale, setScale] = useState("major");
  const [highlightedNoteIndex, setHighlightedNoteIndex] = useState(-1);

  const playScale = () => {
    // create audio context
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    // create oscillator
    const oscillator = audioCtx.createOscillator();
    const toPlay = getScaleByNoteAndType(note, scale).notes;
    // transpose first note in toPlay up by 1 octave and push onto toPLay
    toPlay.push(Note.transpose(toPlay[0], '8P'));
    // create a queue of notes to play
    let currentNote = 0;
    // set oscillator frequency to the first note in the queue
    oscillator.frequency.value = Note.freq(toPlay[currentNote]);
    // in a loop play each note in the toPlay queue for 1 second each
    const playNextNote = () => {
      if (currentNote < toPlay.length) {
        setHighlightedNoteIndex(currentNote);
        //create new oscillator 
        const oscillator = audioCtx.createOscillator();
        // set the frequency to the next note in the queue
        // make oscillator a triangle wave
        oscillator.type = 'triangle';
        oscillator.attackTime = 0.1;
        oscillator.releaseTime = 0.1;
        var gain = audioCtx.createGain();
        oscillator.frequency.value = Note.freq(toPlay[currentNote]);
        oscillator.connect(gain);
        gain.connect(audioCtx.destination);
        oscillator.start();
        gain.gain.setTargetAtTime(0, audioCtx.currentTime, 0.25);

        oscillator.stop(audioCtx.currentTime + 0.5);

        currentNote++;
        setTimeout(playNextNote, 500);
      }
      else {
        setHighlightedNoteIndex(-1);
      }
    }
    playNextNote();
  };

  const fetchScale = () => {
    // fetch from api.muse.cande.la/scale/:note/:scale
    fetch(`/api/scales/${note}/${scale}/midi`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  }

  const scaleToShow = getScaleByNoteAndType(note, scale).notes;
  scaleToShow.push(Note.transpose(scaleToShow[0], '8P'));
  return (
    <PageContainer name="Scales">
      <div>
        <NotesDropDown init={note} onChange={setNote} />
        <ScalesDropDown init={scale} onChange={setScale} />
        <div className="scale">
          {scaleToShow.map((note, index) => (
            <span
              key={index}
              className={highlightedNoteIndex == index ? "highlighted" : ""}
            >{note} </span>
          ))}
        </div>
        <button onClick={playScale}>Play Scale</button>
        <button onClick={fetchScale}>Get Midi File</button>
      </div>
    </PageContainer>
  );
}
