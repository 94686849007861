import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import PageContainer from './components/PageContainer';

export default function Home() {
  return (
    <div>
      <PageContainer name={''}>
        <div className='pages-list'>
          <ul>
            <li>
              <Link to='bpm-calculator'>BPM Calculator</Link>
            </li>
            <li>
              <Link to='frequency-converter'>Frequency Calculator</Link>
            </li>
            <li>
              <Link to='scale-generator'>Scale Generator</Link>
            </li>
          </ul>
        </div>
      </PageContainer>
    </div>
  );
}
