import React, { useState } from 'react';
import { noteToFreq, midiCodes } from '../utils/musicUtils';
import './FrequencyCalculator.css'

export default function FrequencyCalculator() {
  // dropdown menu with the keys of midiCodes
  const [note, setNote] = useState(Object.keys(midiCodes)[0]);
  // drop-down list of notes
  
  const choiceChange = (event) => {
    setNote(event.target.value);
  }

  return (
    <>
      <label htmlFor="note-dropdown">Note:</label>
      <select id="note-dropdown" className="note-dropdown" value={note} onChange={choiceChange}>
        {Object.keys(midiCodes).map((note, index) => (
          <option key={index} value={note}>
            {note}
          </option>
        ))}
      </select>
      <h3>{noteToFreq(note)}</h3>
    </>
  );
}
