const sumOfIntervals = times => {
  if (times.length < 2) throw Error('Need at least two times');
  return times
    .slice(1)
    .map((t, i) => t - times[i])
    .reduce((a, v) => a + v);
};

const averageOfIntervals = times => {
  if (times.length < 2) throw Error('Need at least two times');
  return sumOfIntervals(times) / (times.length - 1);
};

const bpmFromTaps = timesOfTaps => {
  return bpmFromIntervalInMs(averageOfIntervals(timesOfTaps));
};

const bpmFromIntervalInMs = interval => {
  if (interval === 0) throw Error('Interval cannot be 0');
  return Math.round(60000.0 / interval);
};

module.exports = { 
  bpmFromTaps, 
  sumOfIntervals, 
  bpmFromIntervalInMs, 
  averageOfIntervals,
};
